"use strict";

//wow-animate
new WOW().init();

(function ($) {
  $(document).ready(function () {
    ///// header-burger /////
    $('.header__burger').on('click', function () {
      if ($(this).hasClass('active')) {
        $(this).toggleClass("active");
        $('body').css('overflow', 'auto');
        $('header').addClass('header_right');
        $('body').removeClass('open');
      } else {
        $('header').removeClass('header_right');
        $(this).toggleClass("active");
        $('body').css('overflow', 'hidden');
        $('body').addClass('open');
      }
    }); ///// header-scroll /////

    var scrollChange = 5;

    function headerScroll(params) {
      var scroll = $(window).scrollTop();

      if (scroll >= scrollChange) {
        $('.header').addClass('header_scroll');
      } else {
        $('.header').removeClass('header_scroll');
      }
    }

    $(document).on('scroll', function () {
      headerScroll();
    });
    headerScroll(); ///// LogoSlider /////

    var servicesSlider = new Swiper('.swiper-services', {
      slidesPerView: 3.5,
      speed: 1000,
      spaceBetween: 30,
      loop: true,
      autoplay: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.5,
          spaceBetween: 20
        },
        450: {
          slidesPerView: 1.5,
          spaceBetween: 20
        },
        767: {
          slidesPerView: 1.5,
          spaceBetween: 20
        },
        991: {
          slidesPerView: 2.5,
          spaceBetween: 20
        },
        1279: {
          slidesPerView: 3.5,
          spaceBetween: 30
        }
      }
    }); //hidden-text

    var buttons = document.querySelectorAll(".show-btn");
    buttons.forEach(function (button) {
      button.addEventListener("click", function () {
        var container = this.parentNode;
        var hiddenText = container.querySelector(".hidden-text");
        var showBtn = container.querySelector(".show-btn");

        if (hiddenText.style.display === "none") {
          hiddenText.style.display = "block";
          showBtn.innerHTML = "Show Less";
        } else {
          hiddenText.style.display = "none";
          showBtn.innerHTML = "Show All";
        }
      });
    });
  });
})(jQuery);